<script setup lang="ts">
import {computed, type ComputedRef, onMounted, onServerPrefetch} from 'vue'
import {useStore} from '@/stores'
import {useI18n} from 'vue-i18n'
import Icon from '@/components/partials/Icon.vue'
import type {ServiceAlert} from '@/http/models/serviceAlert'
import {useServiceAlertHttp} from '@/http/serviceAlertHttp'

const {currentServiceAlert} = useServiceAlertHttp()
const store = useStore()
const {t} = useI18n()

onMounted(async () => {
  if (store.state.serviceAlert.ssr) {
    await store.dispatch('serviceAlert/setSSR', false)
  } else {
    const serviceAlert = await currentServiceAlert()

    await store.dispatch('serviceAlert/setServiceAlert', serviceAlert)
  }
})

onServerPrefetch(async () => {
  const serviceAlert = await currentServiceAlert()

  await store.dispatch('serviceAlert/setServiceAlert', serviceAlert)
  await store.dispatch('serviceAlert/setSSR', true)
})

const serviceAlerts: ComputedRef<ServiceAlert[]> = computed(() => store.getters['serviceAlert/serviceAlert'])
</script>

<template>
  <TransitionGroup name="list" tag="div">
    <div v-for="alert in serviceAlerts" :key="alert.id" class="notification" :class="`notification__${alert.type}`">
      <Icon :name="alert.type" class="b-icon-medium"/>
      <span class="notification__type bold">{{ t(alert.type) }}:</span>
      <p>{{ alert.message }}</p>
    </div>
  </TransitionGroup>
</template>

<style scoped lang="scss">
.notification {
  position:         absolute;
  top:              0;
  z-index:          40;
  left:             0;
  right:            0;
  display:          flex;
  align-items:      center;
  gap:              0.5rem;
  width:            100%;
  margin-bottom:    1rem;
  padding:          1rem .5rem;
  background-color: #ffffff;
  border:           1px solid;
  border-bottom-left-radius:    0.5rem;
  border-bottom-right-radius:    0.5rem;

  &__type {
    &::first-letter {
      text-transform: uppercase;
    }
  }

  &__error {
    background-color: #f2c5c3;
    border-color:     #c57e7f;
    color:            #cd3137;
  }

  &__warning {
    background-color: #f9f2d2;
    border-color:     #d9cb7b;
    color:            #9b6f2c;
  }

  &__notice {
    background-color: #c6e8f6;
    border-color:     #6bbde1;
    color:            #2473a2;
  }

  &__news {
    background-color: #d9f2d3;
    border-color:     #a8cca0;
    color:            #54714f;
  }
}

.list-enter-active,
.list-leave-active {
  transition: all 1s ease;
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
}
</style>
