import { useAxios } from '@/composables/useAxios'
import type { ServiceAlert } from '@/http/models/serviceAlert'
import { parseISO } from 'date-fns'

export function useServiceAlertHttp() {
  const axios = useAxios()

  async function currentServiceAlert(): Promise<ServiceAlert[]> {
    const { data } = await axios({ method: 'GET', url: 'service-alerts' })

    return data.data.map((serviceAlert: ServiceAlert) => ({
      ...serviceAlert,
      from: parseISO(serviceAlert.from as unknown as string),
      to: parseISO(serviceAlert.to as unknown as string),
    }))
  }

  return {
    currentServiceAlert,
  }
}
